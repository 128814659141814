<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <!--begin::Post-->
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <!--begin::Container-->
      <div id="kt_content_container" class="container">
        <!--begin::Basic info-->
        <div class="card mb-5 mb-xl-10">
          <!--begin::Card header-->
          <div
            class="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
          >
            <!--begin::Card title-->
            <div class="card-title m-0">
              <h3 class="fw-bolder m-0">แก้ไขสินค้า</h3>
            </div>
            <!--end::Card title-->
          </div>
          <!--begin::Card header-->

          <!--begin::Content-->
          <div id="kt_account_profile_details" class="collapse show">
            <!--begin::Form-->
            <Form
              v-if="store"
              @submit="onSubmitStore"
              :validation-schema="storeValidator"
              id="kt_account_profile_details_form"
              class="form"
              novalidate="novalidate"
            >
              <!--begin::Card body-->
              <div class="card-body border-top p-9">
                <!--begin::Input group-->
                <BaseInput name="id" type="hidden" v-model="store.id" />
                <BaseInput
                  label="ชื่อร้าน"
                  required="required"
                  name="name"
                  type="text"
                  v-model="store.name"
                />

                <BaseSelect
                  required="required"
                  label="ประเภทธุรกิจ"
                  name="type"
                  type="text"
                  :data="store_types"
                  v-model="store.type"
                />

                <BaseImage
                  label="รูปภาพ"
                  required="required"
                  name="image_store"
                  :image="store.image"
                  :aspectRatio="4 / 3"
                  :height="1200"
                  :width="1200"
                />

                <BaseInput
                  required="required"
                  label="รายละเอียดร้าน"
                  as="textarea"
                  name="detail"
                  v-model="store.detail"
                />

                <BaseInput
                  label="บ้านเลขที่"
                  required="required"
                  name="house_no"
                  type="text"
                  v-model="store.house_no"
                />

                <BaseInput
                  label="หมู่ที่"
                  name="village_no"
                  v-model="store.village_no"
                  type="text"
                />

                <BaseInput
                  label="ซอย"
                  name="lane"
                  v-model="store.lane"
                  type="text"
                />

                <BaseInput
                  label="ถนน"
                  name="road"
                  v-model="store.road"
                  type="text"
                />

                <BaseSelect
                  required="required"
                  label="อำเภอ"
                  name="sub_district"
                  type="text"
                  :data="uniqueAmphoe"
                  v-model="data_c.amphoe"
                  l="amphoe"
                  k="amphoe"
                  d="amphoe"
                />

                <BaseSelect
                  required="required"
                  label="ตำบล"
                  name="district"
                  type="text"
                  :data="uniqueDistrict"
                  v-model="data_c.district"
                  @change="setZipcode(data_c.district)"
                  l="district"
                  k="district"
                  d="district"
                />

                <BaseInput
                  label="เบอร์โทรศัพท์"
                  required="required"
                  name="tel"
                  type="text"
                  v-model="store.tel"
                />

                <BaseInput
                  label="ลิ้งค์เว็บไซต์"
                  name="website"
                  v-model="store.website"
                  type="text"
                />

                <BaseInput
                  label="ID Line"
                  name="line"
                  v-model="store.line"
                  type="text"
                />

                <BaseInput
                  label="ลิ้งค์ Facebook"
                  name="facebook"
                  v-model="store.facebook"
                  type="text"
                />

                <BaseSelect
                  required="required"
                  label="สถานะ"
                  name="status"
                  type="text"
                  :data="status"
                  v-model="store.status"
                />
              </div>
              <!--end::Card body-->

              <!--begin::Actions-->
              <div class="card-footer d-flex justify-content-end py-6 px-9">
                <button
                  type="submit"
                  id="kt_account_profile_details_submit"
                  ref="submitButton"
                  class="btn btn-primary"
                >
                  <span class="indicator-label"> ยืนยัน </span>
                  <span class="indicator-progress">
                    กรุณารอสักครู่ ...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
              </div>
              <!--end::Actions-->
            </Form>
            <!--end::Form-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Basic info-->
      </div>
    </div>
  </div>
</template>

<script>
import BaseImage from "@/components/modals/BaseImage.vue";
import BaseInput from "@/components/modals/BaseInput.vue";
import BaseSelect from "@/components/modals/BaseSelect.vue";
import json from "@/core/services/DataStore.json";
import { defineComponent, onMounted, ref, reactive, computed } from "vue";
import { Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import useSweetalert from "@/core/helpers/sweetalert2";
import useStores from "@/core/services/api/travel/entrepreneur/store";
import { checkPage } from "@/core/helpers/checkpage";
import "vue-advanced-cropper/dist/style.css";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "entrepreneur-store-edit",
  components: {
    Form,
    BaseInput,
    BaseSelect,
    BaseImage,
  },
  props: ["id"],
  setup(props) {
    const router = useRouter();
    const { getStore, updateStore } = useStores();
    const { Sconfirm, SToast, SconfirmNc } = useSweetalert();
    const submitButton = ref(null);
    const store = ref("");
    const items = ref(json);

    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);
    const status = [
      { id: "แสดง", name: "แสดง" },
      { id: "ไม่แสดง", name: "ไม่แสดง" },
    ];
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const store_types = [
      { id: "ร้านอาหาร", name: "ร้านอาหาร" },
      { id: "ร้านกาแฟ/เครื่องดื่ม", name: "ร้านกาแฟ/เครื่องดื่ม" },
      { id: "โรงแรม/ที่พัก", name: "โรงแรม/ที่พัก" },
      { id: "เสริมสวย/ตัดผม", name: "เสริมสวย/ตัดผม" },
      { id: "เสื้อผ้า/เครื่องแต่งการ", name: "เสื้อผ้า/เครื่องแต่งการ" },
      { id: "ร้านขายของชำ", name: "ร้านขายของชำ" },
      { id: "ร้านซ่อม", name: "ร้านซ่อม" },
      { id: "สปา/นวด", name: "สปา/นวด" },
      { id: "ร้านขายยา/คลินิค", name: "ร้านขายยา/คลินิค" },
      { id: "อุปกรณ์สื่อสาร/ไอที", name: "อุปกรณ์สื่อสาร/ไอที" },
      { id: "อื่นๆ", name: "อื่นๆ" },
    ];
    const data_c = reactive({
      province: "แม่ฮ่องสอน",
      amphoe: "",
      district: "",
      zipcode: "",
    });
    const storeValidator = Yup.object().shape({
      name: Yup.string().required("กรุณากรอก ชื่อร้าน").label("ชื่อร้าน"),
      type: Yup.string()
        .required("กรุณาเลือก ประเภทธุระกิจ")
        .label("ประเภทธุระกิจ"),
      image_store: Yup.string().required("กรุณาเลือก รูปภาพ").label("รูปภาพ"),
      detail: Yup.string().required("กรุณากรอก รายละเอียดร้าน"),
      house_no: Yup.string()
        .required("กรุณากรอก บ้านเลขที่")
        .label("บ้านเลขที่"),
      sub_district: Yup.string().required("กรุณาเลือก อำเภอ").label("อำเภอ"),
      district: Yup.string().required("กรุณาเลือก ตำบล").label("ตำบล"),
      tel: Yup.string()
        .required("กรุณากรอกเบอร์โทรศัพท์")
        .matches(phoneRegExp, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง")
        .min(10, "เบอร์โทรศัพท์ไม่ครบ")
        .max(10, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"),
      status: Yup.string()
        .required("กรุณาเลือก สถานะ")
        .typeError("กรุณากรอก สถานะ"),
    });

    const onSubmitStore = (values) => {
      values.postal_code = data_c.zipcode;
      Sconfirm("ยืนยัน การแก้ไขร้านผู้ประกอบการ", "question").then(
        async (result) => {
          if (result.isConfirmed) {
            if (submitButton.value) {
              // Activate indicator
              submitButton.value.setAttribute("data-kt-indicator", "on");
              submitButton.value.setAttribute("disabled", "disabled");
            }
            await updateStore(values)
              .then(() => {
                SToast("success", "แก้ไข ร้านผู้ประกอบการ สำเร็จ");
                submitButton.value?.removeAttribute("data-kt-indicator");
                submitButton.value?.removeAttribute("disabled");
              })
              .catch(() => {
                submitButton.value?.removeAttribute("data-kt-indicator");
                submitButton.value?.removeAttribute("disabled");
                SconfirmNc(
                  "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
                  "error",
                  "ลองอีกครั้ง !"
                );
              });
          }
        }
      );
    };

    const uniqueAmphoe = computed(() => {
      let dd = items.value.filter((item) => item.province === data_c.province);
      return dd.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.amphoe]: current,
        });
      }, {});
    });

    const uniqueDistrict = computed(() => {
      let dd = items.value.filter((item) => item.amphoe === data_c.amphoe);
      return dd.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.district]: current,
        });
      }, {});
    });

    function setZipcode(val) {
      let result = val;

      let data = items.value.filter(
        (item) => item.district === result && item.amphoe === data_c.amphoe
      );
      data_c.zipcode = data[0].zipcode;
    }

    onMounted(() => {
      if (!props.id) {
        return router.push({ name: "apps.travels.entrepreneur.store.index" });
      }
      getStore(props.id)
        .then((response) => {
          store.value = response.data.data;
          data_c.amphoe = response.data.data.sub_district;
          data_c.district = response.data.data.district;
          data_c.zipcode = response.data.data.postal_code;
          isLoading.value = false;
        })
        .catch(() => {
          SconfirmNc(
            "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
            "error",
            "ลองอีกครั้ง !"
          );
        });

      checkPage("entrepreneur-store-create");
      setCurrentPageBreadcrumbs("แก้ไขร้านผู้ประกอบการ", [
        "ส่งเสริมการท่องเที่ยว",
        "ร้านผู้ประกอบการ",
      ]);
    });

    return {
      store,
      storeValidator,
      submitButton,
      onSubmitStore,
      isLoading,
      color,
      status,
      data_c,
      uniqueAmphoe,
      uniqueDistrict,
      setZipcode,
      store_types,
    };
  },
});
</script>
<style scoped></style>
